/**
 * APIM_SERVICE contains the base routes of the apim services.
 */
export enum APIM_SERVICE {
	AUTH = "auth",
	POWER_BI = "powerbi",
	MAINTENANCE_DLQ = "maintenancedlq",
	REPROCESSING = "reprocessing",
	REGEN = "regen",
	EMPLOYEES = "employees",
}
